<template>
  <section id="mensaje_cookie" class="mensaje_cookie">
    <div class="mensaje_cookie__contenido">
      <div v-if="configurar" class="mensaje_cookie__mensajes">
        <div class="contenido">
          <p class="contenido__titulo">
            Tu privacidad es importante para nosotros
          </p>
          <p class="contenido__texto">
            Nos gustaría utilizar cokies opcionales para entender mejor su uso
            de este sitio web, mejorar nuestro sitio web y compartir información
            con nuestros socios publicitarios. Su consentimiento también cubre
            la transferencia de sus Datos Personales a terceros países no
            seguros, especialmente a los Estados Unidos, lo que implica el
            riesgo de que las autoridades públicas puedan acceder a sus Datos
            Personales para vigilancia y otros fines sin medidas efectivas.
            Puede encontrar información detallada sobre el uso de cookies
            estrictamente necesarias, que son esenciales para navegar en este
            sitio web, y cookies opcionales, que puede rechazar o aceptar a
            continuación, y sobre cómo puede cambiar o revocar su consentimiento
            en cualquier momento en nuestra
            <a class="contenido__enlace" href="/politica-de-privacidad">Política de Privacidad.</a>
          </p>
        </div>
        <div class="botones">
          <button class="botones__button" role="button" aria-label="Aceptar todas" @click="aceptarTodas">
            ACEPTAR TODAS
          </button>
          <button class="botones__button" role="button" aria-label="Rechazar todas" @click="rechazarTodas">
            RECHAZAR TODAS
          </button>
          <button class="botones__button" role="button" aria-label="Configurar" @click="configurar = !configurar">
            CONFIGURAR
          </button>
        </div>
      </div>
      <div v-else class="mensaje_cookie__mensajes">
        <div class="contenido">
          <p class="contenido__volver" @click="configurar = !configurar">
            Volver
          </p>
          <p class="contenido__titulo">
            Tu privacidad es importante para nosotros
          </p>
          <p class="contenido__texto">
            Puedes configurar tus preferencias y elegir como quieres que tus
            datos sean utilizados para los siguientes propósitos.
          </p>
          <form id="form_config" class="contenido__form">
            <div class="opciones">
              <div class="opciones__input">
                <input disabled checked type="checkbox" name="cookies-obligatorias" value="obligatorias"
                  id="obligatorias" aria-label="Obligatorias" />
                <span class="opciones__circulo"></span>
                <label>Obligatorias</label>
              </div>

              <p class="opciones__texto">
                Cookies estrictamente necesarias para prestar un servicio o
                contenido solicitado por el usuario. éstas se encargan de
                aspectos técniso como identificar registros y gestionar
                preferencias de herramientas en el uso, interacción y
                localización del acceso a los contenidos.
              </p>
            </div>
            <div class="opciones">
              <div id="opciones_estadisticas" class="opciones__input">
                <input type="checkbox" name="cookies-estadistica" value="estadistica" id="estadisticas"
                  aria-label="Estadisticas" />
                <span class="opciones__circulo"></span>
                <label for="estadisticas">Estadisticas</label>
              </div>
              <p class="opciones__texto">
                Estas cookies recogen información relativa al uso de la página
                como la permanencia en página y los contenidos visitados por los
                lectores para medir su rendimiento y mejorar la navegación.
              </p>
            </div>
          </form>
        </div>
        <div class="botones">
          <button class="botones__button" role="button" aria-label="Rechazar todas" @click="rechazarTodas">
            RECHAZAR TODO
          </button>
          <button class="botones__button" role="button" aria-label="Aceptar todas" @click="aceptarTodas">
            HABILITAR TODO
          </button>
          <button class="botones__button" role="button" aria-label="Guardar y Salir" @click="guardarCookies">
            GUARDAR Y SALIR
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "@vue/reactivity";

export default {
  name: "Mensaje Footer",
  mounted() {
    this.cargarCookies();
  },
  setup(props) {
    const configurar = ref(true);
    return { configurar };
  },
  methods: {
    cargarCookies() {
      let OBLIGATORIAS = 1;
      let ESTADISTICA = 2;
      var cookieConsent = parseInt(this.getCookie("cookie-consent"));

      if (!cookieConsent) {
        this.abrirCookies();
      } else {
        if (cookieConsent === ESTADISTICA) {
          var cookieAnalytics1 = this.getCookie("_ga");
          var cookieAnalytics2 = this.getCookie("_gid");
          if (!cookieAnalytics1 || !cookieAnalytics2) {
            this.runAnalytics();
          }
        }
        this.closeCookies();
      }
    },
    aceptarTodas() {
      this.runAnalytics();
      this.setCookie("cookie-consent", 2, 1);
      this.closeCookies();
    },
    rechazarTodas() {
      this.setCookie("cookie-consent", 1, 1);
      this.closeCookies();
    },
    guardarCookies() {
      var element = document.getElementById("estadisticas");
      if (element.checked) {
        this.aceptarTodas();
      } else {
        this.rechazarTodas();
      }
    },
    runAnalytics() {
      var Url = window.location;
      if (
        Url.host == "proteccionmasalladelcorazon.es" ||
        Url.host == "www.proteccionmasalladelcorazon.es"
      ) {
        const tagManagerJs = document.createElement("script");
        tagManagerJs.setAttribute("async", "async");
        tagManagerJs.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=G-KYGTFN46LL");

        const analyticsUrl = document.createElement("script");
        analyticsUrl.textContent = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-KYGTFN46LL');";

        document.head.appendChild(tagManagerJs);
        document.head.appendChild(analyticsUrl);
      }
    },
    abrirCookies() {
      var element = document.getElementById("mensaje_cookie");
      element.classList.add("mensaje_cookie--visible");
    },
    closeCookies() {
      var element = document.getElementById("mensaje_cookie");
      element.classList.remove("mensaje_cookie--visible");
    },
    setCookie(nombre, val, dias) {
      var d = new Date();
      d.setTime(d.getTime() + dias * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toGMTString();
      document.cookie = nombre + "=" + val + "; " + expires + ";path=/";
    },
    getCookie(cnombre) {
      var nombre = cnombre + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(nombre) == 0) return c.substring(nombre.length, c.length);
      }
      return false;
    },
  },
};
</script>